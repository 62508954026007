import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="History of SIMTRIM" />
    <h1>History of SIMTRIM</h1>
    <p>The idea of having removable and replaceable molding and trim  was arrived upon in the late 1970’s by SIMTRIM™   Molding System’s developer, Dave Simonar.  It was during a summer job of having to paint the inside and outside of our home town school that it became apparent that there had to be a better way to perform  professional looking paint jobs.</p>
    <p>We found that it took more time to mask the molding and trim than it did to paint the walls. It also didn’t seem to matter how carefully we applied the masking or how carefully we removed the masking, there always seemed to be new paint bleeding underneath the tape and new or old paint was pulled off of the wall when the tape was removed.</p>
    <p>The key was to develop a system that would allow the majority of already installed conventional molding and trim and the majority of new conventional molding or trim to become totally removable and replaceable. By simply using a modified fence hold down system  and a proper dovetail router bit on nearly any home router table, the system can be utilized and installed by nearly any home handy person.</p>
    <p>The majority of tools that are used with the SIMTRIM™  Molding System are far less complicated than existing methods of installing molding or trim.  After a proper dovetail  is installed in the back of conventional wood or MDF molding or trim, the patented male and female snap components of the SIMTRIM™ Molding System  can then make the molding or trim totally removable and replaceable.</p>
    <p>Not only can new molding or trim utilize the SIMTRIM™  System, most existing molding or trim can be carefully removed from walls and the SIMTRIM™  System can be used with them as well.  In instances where commercial or industrial buildings use metal studs this will not affect the use of SIMTRIM™ in any way, because the SIMTRIM™  System uses all purpose screws to make the system work.</p>
    <p>Think of all of the new opportunities available in office building construction if the contractor  is able to use conventional  wooden or MDF molding or trim.</p>
  </Layout>
)

export default SecondPage
